import React, { FC, forwardRef, useImperativeHandle, useRef, useState } from 'react';

import ReCAPTCHA from 'react-google-recaptcha';

import env from '@common/env';
import logger from '@common/log';
import { I18nProvider, useTranslation } from '@i18n';
import { Error, Grid } from '@sparky';
import { useValidationErrorEvent } from '@sparky/hooks';

import RichText from '../RichText/RichText';

const RECAPTCHA_SITE_KEY = env('RECAPTCHA_SITE_KEY');

export type RecaptchaRef = {
  verify: () => Promise<string>;
} | null;

type RecaptchaProps = {
  hasError?: boolean;
  onVerifyChange?: (token: string) => void;
};

const RecaptchaDisclaimer: FC = () => {
  const { t } = useTranslation();
  return (
    <RichText
      html={t('disclaimer.text', {
        privacyPolicyText: t('disclaimer.privacyPolicy.text'),
        privacyPolicyUrl: t('disclaimer.privacyPolicy.url'),
        termsOfServiceText: t('disclaimer.termsOfService.text'),
        termsOfServiceUrl: t('disclaimer.termsOfService.url'),
      })}></RichText>
  );
};

const RecaptchaError: FC<{ hasError?: boolean }> = ({ hasError }) => {
  const { t } = useTranslation();
  const errorRef = useRef<HTMLDivElement>(null);

  const error = hasError ? t('tokenError') : undefined;

  useValidationErrorEvent({ error, name: 'recaptchaToken' }, errorRef);

  if (!error) return null;

  logger.error('B47m4n', 'Unexpected undefined recaptcha token while validating form');

  return (
    <Grid.Item ref={errorRef}>
      <Error hasArrow={false}>{error}</Error>
    </Grid.Item>
  );
};

export const Recaptcha = forwardRef<RecaptchaRef, RecaptchaProps>(
  ({ hasError, onVerifyChange }: RecaptchaProps, ref) => {
    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const [recaptchaToken, setRecaptchaToken] = useState('');

    useImperativeHandle(ref, () => ({
      verify: async () => {
        let token = recaptchaToken;
        if (!token) {
          token = (await recaptchaRef.current?.executeAsync()) ?? '';
          setRecaptchaToken(token);
        }

        onVerifyChange?.(token);

        return token;
      },
    }));

    return (
      <I18nProvider dictionary={locale => import(`./content/${locale}.json`)}>
        <Grid gap="4">
          <Grid.Item>
            <RecaptchaDisclaimer />
            <ReCAPTCHA ref={recaptchaRef} sitekey={RECAPTCHA_SITE_KEY} size="invisible" />
          </Grid.Item>
          <RecaptchaError hasError={hasError} />
        </Grid>
      </I18nProvider>
    );
  },
);
