import React, { RefObject } from 'react';

import { FieldValues, SubmitHandler, useFormContext } from 'react-hook-form';

import { Form } from '@sparky';
import { FormProps } from '@sparky/types';

import { RecaptchaRef } from '../Recaptcha/Recaptcha';

export type RecaptchaFormProps<T extends FieldValues> = Omit<FormProps, 'onSubmit'> & {
  onSubmit: SubmitHandler<T>;
  recaptchaRef: RefObject<RecaptchaRef>;
};

export const RecaptchaForm = <T extends FieldValues>({
  children,
  onSubmit,
  recaptchaRef,
  ...rest
}: React.PropsWithChildren<RecaptchaFormProps<T>>) => {
  const methods = useFormContext();

  if (methods === null) {
    throw new Error(`RecaptchaForm must be used within a FormProvider`);
  }

  const { handleSubmit, setValue } = methods;

  const submitForm = async (event: React.FormEvent) => {
    event.preventDefault();
    setValue('recaptchaToken', await recaptchaRef.current?.verify());
    return handleSubmit((data: FieldValues) => onSubmit(data as T))();
  };

  return (
    <Form onSubmit={submitForm} {...rest}>
      {children}
    </Form>
  );
};
